.offerTitle {
    text-transform: capitalize;
    font-size: 26px;
    font-weight: 500;
    background-color: #fff;
    color: #05141F;
    padding: 20px 20px 40px 20px;
    width: 100%;
    height: 30px;

    @include media(">=768px"){
        padding-left: 30px;
    }

}

.offerContainer {
	font-family: 'Kia Regular', sans-serif;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    width: 100%;
    // height: auto;
    height: 48vh;
    padding: 20% 4% 10em 4%;


    p {
        color: #05141F;
        font-size: 14px;
        margin-bottom: 5px;
    }

    p:last-child {
        color: #777;
    }
        
    button {
    	font-family: 'Kia Regular', sans-serif;
        font-size: 14px;
        width: 123px;
        height: 40px;
        color: #fff;
        background-color: #05141F;
        border: none;
        cursor: pointer;
        border-radius: 25px;
    }

}

.offerContainerResults {
    font-family: 'Kia Regular', sans-serif;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    width: 100%;
    height: auto;
    padding: 10% 4% 30px 4%;


    p {
        color: #05141F;
        font-size: 14px;
        margin-bottom: 5px;
    }

    p:last-child {
        color: #777;
    }
        
    button {
        font-family: 'Kia Regular', sans-serif;
        font-size: 14px;
        width: 123px;
        height: 40px;
        color: #fff;
        background-color: #05141F;
        border: none;
        cursor: pointer;
        border-radius: 25px;
    }

}


.offerBtnDisable {
    opacity: 0.25;
}

.offerBtnActive {
    opacity: 1;
}