.qrContainer {
    display: none;
}


@media only screen and (min-width: 1200px) {

    .qrContainer {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 200;
        background-color: #05141F;
        overflow: hidden;

        .ctaQRContainer {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            color: white;

            img:first-child {
                max-width: 300px;
                margin-bottom: 40px;
            }

            img:last-child {
                margin-top: 40px;
                max-width: 150px;

            }


        }

        .termsContainer {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 20px;
            padding: 10px;
            position: relative;
            bottom: 3%;

             a {
                text-align: center;
                color: #fff;
                font-size: 9px;
                padding-right: 2%;
                text-decoration: underline;
             }
        }


    }



} 


