.app {
    position: relative;
}

.home {
	font-family: 'Kia Regular', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100vw;
    height: 100vh;
    text-align: left;

    img {
        width: 50vw;
        padding: 2%;
        margin-bottom: 10%;
    }

    .linkContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        a {
            text-transform: uppercase;
            font-size: 18px;        
        }

    }
}