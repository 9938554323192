.introContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: black;
    overflow: hidden;



    .video-container {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    
        video {
          width: 100%; 
          height: 100%; 
          object-fit: cover; 
        }

    }




}