.lightModeFooter {
    font-family: 'Kia Regular', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 2% 20px 12% 20px;
    overflow: hidden;

    @include media(">=768px"){
    	padding: 2% 30px 12% 30px;
    }

    div {
        padding: 1% 0 1% 0;
    }

    .listContainer {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        color: #999;
        width: 100%;
        margin-bottom: 10px;
        z-index: 2;

        @include media("<=414px") {
            font-size: 12px;
        }

        p,
        a {
            padding: 5px 0 5px 0;
        }

        .footerHomeLink {
            background-color: transparent;
            border: none;
        }

        .footerModelName {
            font-size: 14px;
            text-transform: capitalize;
            color: #05141F;

            @include media("<=414px") {
                font-size: 13px;
            }

        }
    }

    .socialContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;

        a {
            padding: 7px;
        }
    }

    .discFooterContainer {
        width: 100%;
        height: 30px;

        .discFooter {
            font-size: 9px;
            color: #999;
            padding: 0;
        }
    }

    .termsPrivacyCont {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        a {
            font-size: 9px;
            color: #999;
            padding-right: 2%;
            text-decoration: underline;
        }
    }

    .motifContainer {
        position: relative;
        top: 0;
        left: 0;
        width: 120%;
        height: 60px;
    }


}



.darkModeFooter {
    font-family: 'Kia Regular', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #05141F;
    width: 100%;
    padding: 2% 20px 12% 20px;
    overflow: hidden;

    @include media(">=768px"){
    	padding: 2% 30px 12% 30px;
    }

    div {
        padding: 1% 0 1% 0;
    }

    .listContainer {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        color: #999;
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        z-index: 2;

        @include media("<=414px") {
            font-size: 12px;
        }

        p,
        a {
            padding: 5px 0 5px 0;
        }

        .footerHomeLink {
            background-color: transparent;
            border: none;
        }

        .footerModelName {
            font-size: 14px;
            text-transform: capitalize;
            color: #fff;

            @include media("<=414px") {
                font-size: 13px;
            }

        }
    }

    .socialContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;

        a {
            padding: 7px;
        }
    }

    .discFooterContainer {
        width: 100%;
        height: 30px;

        .discFooter {
            font-size: 9px;
            color: #fff;
            padding: 0;
        }
    }

    .termsPrivacyCont {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        a {
            font-size: 9px;
            color: white;
            padding-right: 2%;
            text-decoration: underline;
        }
    }

    .motifContainer {
        position: relative;
        top: 0;
        left: 0;
        width: 120%;
        height: 60px;


    }





}