.darkModeNav {
    font-family: 'Kia Regular', sans-serif;
    background-color: #05141F;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 20px 20px 20px;
    overflow: hidden;

    @include media(">=768px"){
    	padding:20px 20px 20px 30px;
    }

    .navBtn {
        border: none;
        background-color: transparent;
    }

    .nameContainer {
        border-left: solid 1px #fff;
        margin-left: 10px;

        p {
            text-transform: capitalize;
            margin-left: 10px;
            color: #fff;
        }



    }

}




.lightModeNav {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px 20px 20px 20px;
    overflow: hidden;

    @include media(">=768px"){
    	padding:20px 20px 20px 30px;
    }

    .navBtn {
        border: none;
        background-color: transparent;
    }

    .nameContainer {
        border-left: solid 1px #000;
        margin-left: 10px;

        p {
            text-transform: capitalize;
            margin-left: 10px;
            color: #000;
        }

    }


}

.footerModelName {
    font-family: "Kia Regular", sans-serif;
    border: none;
    margin-left: 10px;
    font-size: 18px;
    color: #05141F;
    background-color: transparent;
    text-transform: capitalize;
}

.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 22;
    left: 0;
    //animation: moveDown 0.5s ease-in-out;
}


// @keyframes moveDown {
//   from {
//     transform: translateY(-5rem);
//   }
//   to {
//     transform: translateY(0rem);
//   }
// }

// @keyframes rotate {
//   0% {
//     transform: rotateY(360deg);
//   }
//   100% {
//     transform: rotateY(0rem);
//   }
// }