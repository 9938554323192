.militarySection {
    font-family: 'Kia Regular', sans-serif;
    background-color: #F4F4F4;
    padding: 20px;
    font-size: 12px;

    @include media(">=768px"){
        padding: 20px 30px 20px 30px;
    }

    .zipId {
        color: #999999;
    }

    .tallyOffer {
        padding: 0;
    }

    .offerModule {
        background-color: #fff;
        padding: 15px;
        margin: 15px 0 15px 0;


        @include media(">=768px"){
            text-align: center;
        }

        .offerDate {
            font-size: 12px;
            color: #525252;
        }

        .offerDesc {
            font-size: 18px;
            margin-bottom: 15px;
            font-weight: 100;
        }

        .ctaContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            button {
                border: solid 1px #000;
                // padding: 10px 30px 10px 30px;
                margin: 15px 10px 15px 10px;
                font-size: 13px;
                width: 141px;
                height: 40px;

                @include media("<=320px"){
                    width: 121px;
                    height: 30px;
                    font-size: 9px;
                }

            }

            .quoteCta {
                background-color: #05141F;
                color: #fff;
            }

            .inventoryCta {
                background-color: #fff;
                color: #000;
            }
        }

    }

    .discContainer {
        font-size: 12px;
        color: #525252;
        padding: 5px 10px 5px 10px;
        background-color: #fff;

        p > b {
            letter-spacing: -0.5px;
        }

    }

}