.resultsSection {
    font-family: 'Kia Regular', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    color: #05141F;

    .topTypeNav {
        padding-left: 20px;

	    @include media(">=768px"){
	   		padding-left: 30px;
	    }

    }

    .jellyContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        img {
            width: 80%;
        }
    }

    .noOfferContainer {

        padding: 0 4% 0 4%;

        .noOffersCopy {
            font-family: 'Kia Regular', sans-serif;
            font-size: 12px;
            color: red;
            margin-bottom: 40px;
            width: 100%;
            height: 30px;
            text-align: left;
            border: none;
            user-select: none;
            resize: none;


		    @include media(">=768px"){
		   		padding-bottom: 4%;
		    }

        }

        .ctaContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            button {
                border: solid 1px #000;
                padding: 10px 30px 10px 30px;
                margin: 15px 10px 15px 10px;
                font-size: 13px;
                width: 155px;
                height: 40px;

                @include media("<=320px"){
                    width: 121px;
                    height: 30px;
                    font-size: 9px;
                }

            }

            .quoteCta {
                background-color: #000;
                color: #fff;
            }

            .inventoryCta {
                background-color: #fff;
                color: #000;
            }
        }


    }

    .btmNav {
        padding-left: 20px;

	    @include media(">=768px"){
	   		padding-left: 30px;
	    }

        h3 {
            font-size: 26px;
            font-weight: 500;
        }

        .backBtmBtn {
            font-size: 18px;
            margin: 60px 15px 60px 0;
            color: #999;


            .btmBackName {
                text-transform: capitalize;
            }
            div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;

                img {
                    margin-right: 5px;
                }

            }
        }
    }

    .activeBtnTypeNav {
        font-family: 'Kia Regular', sans-serif;
        font-size: 18px;
        margin: 15px 15px 0 0;
        color: #000;
        background-color: #fff;
        border: none;
        cursor: pointer;
    }

    .btnTypeNav {
        font-family: 'Kia Regular', sans-serif;
        font-size: 18px;
        margin: 15px 15px 0 0;
        color: #999;
        background-color: #fff;
        border: none;
        cursor: pointer;
    }

}