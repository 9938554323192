.mediaSection {
    font-family: 'Kia Regular', sans-serif;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #05141F;
    width: 100%;
    height: auto;
    margin-bottom: 50px;

    img {
        width: 100%;
        height: auto;
        cursor: pointer;
    }

    p {
        color: #fff;
    }

    div {
        cursor: pointer;
    }

    button {
        border: none;
        background-color: transparent;
    }

    .awardContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        margin: 10px 0 10px 0;

        @media (orientation: landscape) {
            justify-content: center;
        }

        @include media(">=768px"){
            justify-content: center;
        }

        img:nth-child(1){
            padding-right: 30px;
        }

        img:nth-child(2){
            border-left: 1px solid gray;
            padding-left: 30px;
        }

        img {
            width: 50%;
            
            @media (orientation: landscape) {
                width: 30%;
            }

            @include media(">=768px"){
                width: 30%;
            }

        }

    }

    .mediaContainer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 3px;

        @include media(">=1000px"){
            width: 70%;
        }

        .pinchContainer {
            width: 100%;
            height: auto;
            display: flex;


            .pinchBtn {
                width: 27px;
                height: 26px;
                transform: rotate(-90deg);
                position: absolute;
                right: 0;
                margin: 10px;
            
            }
        }

        .mp4Container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;


            .playBtn {
                position: absolute;
                /* width: 66px;
                height: 22px; */
                width: 88px;
                height: 89px;
                // transform: rotate(90deg);
                // flex-shrink: 0;
                // fill: rgba(255, 255, 255, 0.65);
                // backdrop-filter: blur(12.5px);
            }
        }



        .btnCont {
            position: relative;
            text-align: left;
            font-size: 13px;
            padding: 20px 0;
            width: 90%; 
            margin: auto;

            sup {
                padding-left: 4px;
                font-size: 8px;
            }

            @include media(">=768px"){
                left: 30px;
            }


        }


        .imageCaptionCont {
            
            text-align: left;
            left: 20px;
            bottom: 0;
            font-size: 13px;
            padding: 20px 20px 40px 20px;
            width: 90%; 
            height: auto;
            
            @media only screen and (min-width: 900px) and (max-width: 1200px) and (orientation: landscape) {
                /* Styles for tablet in portrait mode */
               padding: 20px 20px 40px 0;
            }

            sup {
                // padding-left: 4px;
                font-size: 8px;
                position: relative;
                left: -1px;
            }

            @include media(">=768px"){
                left: 30px;
            }

        }

        .subtext {
            font-size: 11px; 
        }

    }

    .btnDiscContainer {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;

            @media only screen and (min-width: 900px) and (max-width: 1200px) and (orientation: landscape) {
                /* Styles for tablet in portrait mode */
                margin-bottom: 70px;
            }

            .discContainer {

                margin: 15px 20px 20px 20px;

                @include media(">=768px"){
                    margin: 15px 20px 20px 30px;
                }

                p {
                    font-family: 'Kia Regular', sans-serif;
                    font-size: 16px;
                    text-decoration: underline;
                }
            }

    }

}

.mediaHide {
    display: none;
}