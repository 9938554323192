.accordion-wrapper {
  width: 100%;
  background-color: #fff;

}

.accordion-wrapper:after {
    content: "";  
    display: block; 
    margin: 0 auto; 
    width: 92%; 
    padding-top: 0px; 
    border-bottom: 1px solid #707070; 
}

.accordion-item {
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  display: block;
}

.accordion-item.collapsed {
  display: none;
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {

  width: 100%;
  font-size: 18px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: #707070;
  height: 70px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;

    .offerBtnName {
      font-family: 'Kia Regular', sans-serif;
      margin-left: 4%;
    }

    img {
        width: 120px;
        height: 60px;
        margin: 0 5% 0 2%;
    }

    img + span {
      text-transform: capitalize;
      font-family: 'Kia Regular', sans-serif;
    }

    .arrowContainer.open {
      width: 12px;
      height: 12px;
      transform: rotate(180deg);
    }

    
    .arrowContainer {
      background: url('../../../public/images/global/arrowaccordian.svg') no-repeat;
      width: 12px;
      height: 12px;
      right: 4%;
      position: absolute;
    }



}

.accordion-one {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .blankContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 70px;   
      background-color: #fff;
      border: none;

      span {
        font-family: 'Kia Regular', sans-serif;
        font-size: 18px;
        color: #707070;
      }

      img {
          width: 120px;
          height: 60px;
          margin: 0 5% 0 2%;
      }

      .offerBtnName {
        margin-left: 4%;
      }
  }

}

.accordion-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .btnContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 70px;   
      background-color: #E6E6E6;
      border: none;

      span {
        font-family: 'Kia Regular', sans-serif;
        font-size: 18px;
        cursor: pointer;
        color: #707070;
      }

      img {
          width: 120px;
          height: 60px;
          margin: 0 5% 0 2%;
      }

      .offerBtnName {
        margin-left: 4%;
      }
  }

  .selected {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 70px;   
      background-color: #CCC;
      border: none;

      span {
        font-family: 'Kia Regular', sans-serif;
        font-size: 18px;
        cursor: pointer;
        color: #707070;
      }

      img {
          width: 120px;
          height: 60px;
          margin: 0 5% 0 2%;
      }

      .offerBtnName {
        margin-left: 4%;
      }

  }

}