
.modelHome {
    font-family: 'Kia Regular', sans-serif;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    scroll-behavior: smooth;
    background-color: #05141F;
}

.filler {
    height: 100vh;
    padding-bottom: 1000px;
}


.hideContent {
    display: none;

}
