/* =========================================
  VARIABLES
======================================== */
/* =========================================
  MIXINS
======================================== */
/* =========================================
  FONTS
======================================== */
@font-face {
  font-family: "Kia Bold";
  src: url("../../../public/fonts/KiaSignatureBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Kia light";
  src: url("../../../public/fonts/KiaSignatureLight.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Kia Regular";
  src: url("../../../public/fonts/KiaSignatureRegular.woff") format("woff");
  font-display: swap;
}
/* =========================================
  RESET
======================================== */
* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: "Kia Regular", sans-serif;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  background-color: #05141F;
  font-family: "Kia Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  border: none;
}
a:hover {
  color: inherit;
  text-decoration: none;
}

.hidden {
  display: none;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

img {
  color: #fff;
}

/* =========================================
  TRANSITIONS
======================================== */
header {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-color: #05141F;
  font-size: 26px;
  position: relative;
  overflow: hidden;
  padding-bottom: 140%;
}
@media (orientation: landscape) {
  header {
    padding-bottom: 0;
    background-position: 80% 70%;
  }
}
@media (min-height: 767px) and (max-width: 375px) {
  header {
    background-position: 65% 70%;
  }
}
header h3,
header p {
  color: #fff;
}
header .tagContainer {
  position: absolute;
  width: 170%;
  height: auto;
  left: 20px;
  overflow: hidden;
}
@media (orientation: landscape) {
  header .tagContainer {
    bottom: 4%;
    width: 120%;
  }
}
@media (min-height: 767px) and (max-width: 375px) {
  header .tagContainer {
    top: -10%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) and (orientation: portrait) {
  header .tagContainer {
    /* Styles for tablet in portrait mode */
    top: -45%;
  }
}
header .tagContainer .motif {
  overflow: hidden;
}
header .copyContainer {
  position: absolute;
  font-family: "Kia Regular", sans-serif;
  font-size: 26px;
  width: 70%;
  height: 20%;
  left: 1.8em;
  top: 8%;
  line-height: 1.2em;
  user-select: none;
}
@media (max-width: 375px) {
  header .copyContainer {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  header .copyContainer {
    left: 7%;
    font-size: 29px;
  }
}
@media (min-width: 845px) {
  header .copyContainer {
    left: 7%;
    font-size: 29px;
  }
}
@media (min-width: 1000px) {
  header .copyContainer {
    left: 7%;
    font-size: 39px;
  }
}
header .awardPresent {
  flex-direction: row;
  justify-content: space-between;
}
@media (orientation: landscape) {
  header .awardPresent {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
}
header .awardNone {
  flex-direction: column;
  justify-content: center;
}
header .btmHeaderContainer {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 3rem;
  right: 0px;
}
@media (min-width: 768px) and (max-width: 820px) {
  header .btmHeaderContainer {
    bottom: 9%;
  }
}
header .btmHeaderContainer .awardHdrContainer {
  padding: 20px;
  height: auto;
  width: 50%;
  display: flex;
}
@media (orientation: landscape) {
  header .btmHeaderContainer .awardHdrContainer {
    width: 35%;
  }
}
@media (min-width: 768px) {
  header .btmHeaderContainer .awardHdrContainer {
    width: 29.6%;
  }
}
header .btmHeaderContainer .awardHdrContainer img {
  width: 100%;
}
header .btmHeaderContainer .arrowContainer {
  font-size: 14px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
@media (max-height: 414px) {
  header .btmHeaderContainer .arrowContainer {
    position: absolute;
    flex-direction: row;
    bottom: 7%;
    right: 0;
    padding: 1%;
  }
}
header .btmHeaderContainer .arrowContainer .lmAwardNone {
  display: block;
  cursor: pointer;
}
@media (orientation: landscape) {
  header .btmHeaderContainer .arrowContainer .lmAwardNone {
    padding-top: 10%;
  }
}
header .btmHeaderContainer .arrowContainer .lmAwardPresent {
  cursor: pointer;
  display: none;
}
header .btmHeaderContainer .arrowContainer button {
  background-color: transparent;
  background: url("../../../public/images/global/arrowdown.svg") no-repeat 50% 10px;
  width: 22px;
  height: 22px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 0;
  padding: 20px 20px 20px 60px;
}
@media (max-height: 414px) {
  header .btmHeaderContainer .arrowContainer button {
    padding: 20px 30px 30px 30px;
  }
}

.ev6Header {
  background-image: url("../../../public/images/ev6/homehero.jpg");
  background-position: 80% 70%;
}

.forteHeader {
  background-image: url("../../../public/images/forte/homehero.jpg");
  background-position: 80% 70%;
}

.sportageHeader {
  background-image: url("../../../public/images/sportage/homehero.jpg");
  background-position: 80% 70%;
}

.niroHeader {
  background-position: 34% 20%;
  background-image: url("../../../public/images/niro/homehero.jpg");
}
@media (orientation: landscape) {
  .niroHeader {
    padding-bottom: 0;
    background-position: 5% 98%;
    background-image: url("../../../public/images/niro/homehero_landscape.jpg") !important;
  }
}

.tellurideHeader {
  background-image: url("../../../public/images/telluride/homehero.jpg");
  background-position: 80% 70%;
}
.ev9Header {
  background-image: url("../../../public/images/ev9/homehero.jpg");
  background-position: 65% 20%;
}
@media (orientation: landscape) {
  .ev9Header {
    background-position: 5% 72%;
    background-size: 120%;
  }
}

.k4Header {
  background-image: url("../../../public/images/k4/homehero.jpg");
  background-position: 65% 20%;
}
@media (orientation: landscape) {
  .k4Header {
    background-position: 55% 82%;
    background-size: 120%;
  }
}

.linksSection {
  font-family: "Kia Regular", sans-serif;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #05141F;
  width: 100%;
  padding: 20px 20px 0 20px;
}
@media (min-width: 768px) {
  .linksSection {
    padding: 20px 20px 0px 30px;
  }
}
.linksSection ul {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  padding: 40px 0 0 0;
}
.linksSection ul li {
  padding: 2% 0 2% 0;
}
.linksSection ul li .releaseCopy {
  opacity: 0.4;
}

.mediaSection {
  font-family: "Kia Regular", sans-serif;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #05141F;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}
.mediaSection img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.mediaSection p {
  color: #fff;
}
.mediaSection div {
  cursor: pointer;
}
.mediaSection button {
  border: none;
  background-color: transparent;
}
.mediaSection .awardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin: 10px 0 10px 0;
}
@media (orientation: landscape) {
  .mediaSection .awardContainer {
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .mediaSection .awardContainer {
    justify-content: center;
  }
}
.mediaSection .awardContainer img:nth-child(1) {
  padding-right: 30px;
}
.mediaSection .awardContainer img:nth-child(2) {
  border-left: 1px solid gray;
  padding-left: 30px;
}
.mediaSection .awardContainer img {
  width: 50%;
}
@media (orientation: landscape) {
  .mediaSection .awardContainer img {
    width: 30%;
  }
}
@media (min-width: 768px) {
  .mediaSection .awardContainer img {
    width: 30%;
  }
}
.mediaSection .mediaContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 3px;
}
@media (min-width: 1000px) {
  .mediaSection .mediaContainer {
    width: 70%;
  }
}
.mediaSection .mediaContainer .pinchContainer {
  width: 100%;
  height: auto;
  display: flex;
}
.mediaSection .mediaContainer .pinchContainer .pinchBtn {
  width: 27px;
  height: 26px;
  transform: rotate(-90deg);
  position: absolute;
  right: 0;
  margin: 10px;
}
.mediaSection .mediaContainer .mp4Container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mediaSection .mediaContainer .mp4Container .playBtn {
  position: absolute;
  /* width: 66px;
  height: 22px; */
  width: 88px;
  height: 89px;
}
.mediaSection .mediaContainer .btnCont {
  position: relative;
  text-align: left;
  font-size: 13px;
  padding: 20px 0;
  width: 90%;
  margin: auto;
}
.mediaSection .mediaContainer .btnCont sup {
  padding-left: 4px;
  font-size: 8px;
}
@media (min-width: 768px) {
  .mediaSection .mediaContainer .btnCont {
    left: 30px;
  }
}
.mediaSection .mediaContainer .imageCaptionCont {
  text-align: left;
  left: 20px;
  bottom: 0;
  font-size: 13px;
  padding: 20px 20px 40px 20px;
  width: 90%;
  height: auto;
}
@media only screen and (min-width: 900px) and (max-width: 1200px) and (orientation: landscape) {
  .mediaSection .mediaContainer .imageCaptionCont {
    /* Styles for tablet in portrait mode */
    padding: 20px 20px 40px 0;
  }
}
.mediaSection .mediaContainer .imageCaptionCont sup {
  font-size: 8px;
  position: relative;
  left: -1px;
}
@media (min-width: 768px) {
  .mediaSection .mediaContainer .imageCaptionCont {
    left: 30px;
  }
}
.mediaSection .mediaContainer .subtext {
  font-size: 11px;
}
.mediaSection .btnDiscContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
@media only screen and (min-width: 900px) and (max-width: 1200px) and (orientation: landscape) {
  .mediaSection .btnDiscContainer {
    /* Styles for tablet in portrait mode */
    margin-bottom: 70px;
  }
}
.mediaSection .btnDiscContainer .discContainer {
  margin: 15px 20px 20px 20px;
}
@media (min-width: 768px) {
  .mediaSection .btnDiscContainer .discContainer {
    margin: 15px 20px 20px 30px;
  }
}
.mediaSection .btnDiscContainer .discContainer p {
  font-family: "Kia Regular", sans-serif;
  font-size: 16px;
  text-decoration: underline;
}

.mediaHide {
  display: none;
}

.app {
  position: relative;
}

.home {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100vw;
  height: 100vh;
  text-align: left;
}
.home img {
  width: 50vw;
  padding: 2%;
  margin-bottom: 10%;
}
.home .linkContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.home .linkContainer a {
  text-transform: uppercase;
  font-size: 18px;
}

.modelHome {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  scroll-behavior: smooth;
  background-color: #05141F;
}

.filler {
  height: 100vh;
  padding-bottom: 1000px;
}

.hideContent {
  display: none;
}

.offerTitle {
  text-transform: capitalize;
  font-size: 26px;
  font-weight: 500;
  background-color: #fff;
  color: #05141F;
  padding: 20px 20px 40px 20px;
  width: 100%;
  height: 30px;
}
@media (min-width: 768px) {
  .offerTitle {
    padding-left: 30px;
  }
}

.offerContainer {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 48vh;
  padding: 20% 4% 10em 4%;
}
.offerContainer p {
  color: #05141F;
  font-size: 14px;
  margin-bottom: 5px;
}
.offerContainer p:last-child {
  color: #777;
}
.offerContainer button {
  font-family: "Kia Regular", sans-serif;
  font-size: 14px;
  width: 123px;
  height: 40px;
  color: #fff;
  background-color: #05141F;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.offerContainerResults {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: auto;
  padding: 10% 4% 30px 4%;
}
.offerContainerResults p {
  color: #05141F;
  font-size: 14px;
  margin-bottom: 5px;
}
.offerContainerResults p:last-child {
  color: #777;
}
.offerContainerResults button {
  font-family: "Kia Regular", sans-serif;
  font-size: 14px;
  width: 123px;
  height: 40px;
  color: #fff;
  background-color: #05141F;
  border: none;
  cursor: pointer;
  border-radius: 25px;
}

.offerBtnDisable {
  opacity: 0.25;
}

.offerBtnActive {
  opacity: 1;
}

.resultsSection {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  color: #05141F;
}
.resultsSection .topTypeNav {
  padding-left: 20px;
}
@media (min-width: 768px) {
  .resultsSection .topTypeNav {
    padding-left: 30px;
  }
}
.resultsSection .jellyContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.resultsSection .jellyContainer img {
  width: 80%;
}
.resultsSection .noOfferContainer {
  padding: 0 4% 0 4%;
}
.resultsSection .noOfferContainer .noOffersCopy {
  font-family: "Kia Regular", sans-serif;
  font-size: 12px;
  color: red;
  margin-bottom: 40px;
  width: 100%;
  height: 30px;
  text-align: left;
  border: none;
  user-select: none;
  resize: none;
}
@media (min-width: 768px) {
  .resultsSection .noOfferContainer .noOffersCopy {
    padding-bottom: 4%;
  }
}
.resultsSection .noOfferContainer .ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.resultsSection .noOfferContainer .ctaContainer button {
  border: solid 1px #000;
  padding: 10px 30px 10px 30px;
  margin: 15px 10px 15px 10px;
  font-size: 13px;
  width: 155px;
  height: 40px;
}
@media (max-width: 320px) {
  .resultsSection .noOfferContainer .ctaContainer button {
    width: 121px;
    height: 30px;
    font-size: 9px;
  }
}
.resultsSection .noOfferContainer .ctaContainer .quoteCta {
  background-color: #000;
  color: #fff;
}
.resultsSection .noOfferContainer .ctaContainer .inventoryCta {
  background-color: #fff;
  color: #000;
}
.resultsSection .btmNav {
  padding-left: 20px;
}
@media (min-width: 768px) {
  .resultsSection .btmNav {
    padding-left: 30px;
  }
}
.resultsSection .btmNav h3 {
  font-size: 26px;
  font-weight: 500;
}
.resultsSection .btmNav .backBtmBtn {
  font-size: 18px;
  margin: 60px 15px 60px 0;
  color: #999;
}
.resultsSection .btmNav .backBtmBtn .btmBackName {
  text-transform: capitalize;
}
.resultsSection .btmNav .backBtmBtn div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.resultsSection .btmNav .backBtmBtn div img {
  margin-right: 5px;
}
.resultsSection .activeBtnTypeNav {
  font-family: "Kia Regular", sans-serif;
  font-size: 18px;
  margin: 15px 15px 0 0;
  color: #000;
  background-color: #fff;
  border: none;
  cursor: pointer;
}
.resultsSection .btnTypeNav {
  font-family: "Kia Regular", sans-serif;
  font-size: 18px;
  margin: 15px 15px 0 0;
  color: #999;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.zipCodeFormSection {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 70vh;
  padding: 0 10% 10% 10%;
  background-color: #fff;
}
.zipCodeFormSection p {
  color: #05141f;
  font-size: 26px;
  margin-bottom: 20px;
}
.zipCodeFormSection textarea {
  color: #05141f;
  font-size: 26px;
  margin-bottom: 20px;
  width: 90%;
  font-family: "Kia Regular", sans-serif;
  border: none;
  resize: none;
}
.zipCodeFormSection .mc__field-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
}
.zipCodeFormSection .mc__field-container .errorMsg {
  margin-top: 10px;
  color: #FF0000;
  font-size: 12px;
  border: none;
  user-select: none;
  visibility: hidden;
}
.zipCodeFormSection .mc__field-container .errorMsg input:focus {
  margin-top: 10px;
  color: #FF0000;
  font-size: 12px;
  border: none;
  user-select: none;
}
.zipCodeFormSection .mc__field-container .show {
  visibility: visible;
}
.zipCodeFormSection .mc__field-container .mc__form {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #999;
  width: 100%;
}
.zipCodeFormSection .mc__field-container .mc__form .inputField__label {
  width: 100%;
}
.zipCodeFormSection .mc__field-container .mc__form .submitDim {
  opacity: 0.5;
}
.zipCodeFormSection .mc__field-container .mc__form .submit {
  opacity: 1;
}
.zipCodeFormSection .mc__field-container .mc__form input {
  font-family: "Kia Regular", sans-serif;
  font-size: 18px;
  border: none;
  width: 100%;
  color: #05141f !important;
  padding: 10px;
}
.zipCodeFormSection .mc__field-container .mc__form input:nth-child(2) {
  margin-left: auto;
  background-color: #fff;
  background: url("../../../public/images/global/arrowsubmit.svg") no-repeat 20px 7px;
  color: #fff;
  width: 50px;
  height: 30px;
  border: none;
  padding: 20px;
  cursor: pointer;
  font-size: 0;
}

.modal-container {
  font-family: "Kia Regular", sans-serif;
  background-color: #05141F;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  overflow: hidden;
}
.modal-container .modal {
  background-color: #05141F;
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.modal-container .modal .customBtnVideo {
  font-family: "Kia Regular", sans-serif;
  position: absolute;
  top: 2%;
  right: 2%;
  margin-left: auto;
  background: url("../../../public/images/global/closemodal.svg") no-repeat rgba(0, 0, 0, 0.4);
  background-position: 20px 20px;
  background-color: #05131F;
  width: 60px;
  height: 60px;
  border: none;
  cursor: pointer;
  font-size: 0;
  z-index: 999;
}
@media (max-height: 375px) {
  .modal-container .modal .customBtnVideo {
    top: 22%;
  }
}
.modal-container .modal .customBtnImage {
  font-family: "Kia Regular", sans-serif;
  position: absolute;
  top: 2%;
  right: 2%;
  margin-left: auto;
  background: url("../../../public/images/global/closemodal.svg") no-repeat #05141f;
  background-position: 20px 20px;
  width: 60px;
  height: 60px;
  border: none;
  cursor: pointer;
  font-size: 0;
  z-index: 999;
}
.modal-container .modal p {
  color: #000;
  font-family: sans-serif;
  text-transform: unset;
}
.modal-container .modal .inputFake {
  opacity: 0;
  background-color: transparent;
  border: none;
  width: 0;
  height: 0;
  left: -100px;
  top: -100px;
  position: absolute;
}
.modal-container .modal .main-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-container .modal .main-content .mp4-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.modal-container .modal .main-content .mp4-wrapper video {
  width: 100%;
}
@media (max-height: 414px) {
  .modal-container .modal .main-content .mp4-wrapper video {
    width: 80%;
  }
}
.modal-container .modal .main-content .video-container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50vh;
  transform: translateY(-50%);
  position: relative;
  padding-bottom: 51.25%;
  /* 16:9 */
  width: 100%;
  vertical-align: baseline;
}
.modal-container .modal .main-content .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal-container .modal .main-content .video-container iframe img {
  background-size: contain !important;
}
.modal-container .modal .main-content .image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
}
.modal-container .modal .main-content .image-container .pinchZoomContainer {
  width: 125px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #fff;
  top: 15px;
  padding: 10px;
  left: 10px;
  position: absolute;
  z-index: 2;
}
.modal-container .modal .main-content .image-container .pinchZoomContainer .pinchCopy {
  position: absolute;
  color: #fff;
  font-family: "Kia Regular", sans-serif;
}
@media (orientation: landscape) {
  .modal-container .modal .main-content .image-container .pinchZoomContainer .pinchCopy {
    background: rgba(0, 0, 0, 0.7);
    padding: 2px;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.modal-container .modal .main-content .image-container .pinchImage {
  width: 100%;
}
@media (max-height: 414px) {
  .modal-container .modal .main-content .image-container .pinchImage {
    width: 60vw;
  }
}
.modal-container .modal .main-content .image-container .kvfysmfp {
  touch-action: none;
  overflow: visible;
}
.modal-container .modal .main-content .discModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding: 20% 10% 20% 10%;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
@media (orientation: landscape) {
  .modal-container .modal .main-content .discModalContainer {
    margin-top: 0;
  }
}
.modal-container .modal .main-content .discModalContainer p {
  font-family: "Kia Regular", sans-serif;
  text-align: left;
  font-size: 12px !important;
  color: #fff;
  margin-bottom: 30px;
  background-color: #05131F;
  border: none;
  resize: unset;
  width: 100%;
  height: auto;
}
@media (orientation: landscape) {
  .modal-container .modal .main-content .discModalContainer p {
    font-size: 12px !important;
  }
}
.modal-container .modal .main-content .discModalContainer p sup {
  font-size: 8px;
  padding-right: 4px;
}
.modal-container .modal .main-content .discModalContainer p a {
  text-decoration: underline;
}
.modal-container .modal .main-content .discModalContainer div {
  position: relative;
  top: 0;
  width: 100%;
  height: auto;
  padding-bottom: 5%;
  display: block;
}
.modal-container .modal .main-content .title {
  padding-bottom: 10px;
}
.modal-container .modal .main-content .title p,
.modal-container .modal .main-content .title input {
  font-family: "Kia Regular", sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  padding: 0;
  text-align: center;
  border: 0;
}
@media (max-width: 500px) {
  .modal-container .modal .main-content .title p,
  .modal-container .modal .main-content .title input {
    font-size: 24px;
    line-height: 26px;
  }
}
@media (max-width: 375px) {
  .modal-container .modal .main-content .title p,
  .modal-container .modal .main-content .title input {
    font-size: 20px;
    line-height: 22px;
  }
}
.modal-container .modal .main-content .terms-copy p {
  text-align: left;
  line-height: 20px;
  font-size: 16px;
}
@media (max-width: 500px) {
  .modal-container .modal .main-content .terms-copy p {
    font-size: 16px;
  }
}
@media (max-width: 375px) {
  .modal-container .modal .main-content .terms-copy p {
    font-size: 14px;
  }
}

.lightModeFooter {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 2% 20px 12% 20px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .lightModeFooter {
    padding: 2% 30px 12% 30px;
  }
}
.lightModeFooter div {
  padding: 1% 0 1% 0;
}
.lightModeFooter .listContainer {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #999;
  width: 100%;
  margin-bottom: 10px;
  z-index: 2;
}
@media (max-width: 414px) {
  .lightModeFooter .listContainer {
    font-size: 12px;
  }
}
.lightModeFooter .listContainer p,
.lightModeFooter .listContainer a {
  padding: 5px 0 5px 0;
}
.lightModeFooter .listContainer .footerHomeLink {
  background-color: transparent;
  border: none;
}
.lightModeFooter .listContainer .footerModelName {
  font-size: 14px;
  text-transform: capitalize;
  color: #05141F;
}
@media (max-width: 414px) {
  .lightModeFooter .listContainer .footerModelName {
    font-size: 13px;
  }
}
.lightModeFooter .socialContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.lightModeFooter .socialContainer a {
  padding: 7px;
}
.lightModeFooter .discFooterContainer {
  width: 100%;
  height: 30px;
}
.lightModeFooter .discFooterContainer .discFooter {
  font-size: 9px;
  color: #999;
  padding: 0;
}
.lightModeFooter .termsPrivacyCont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.lightModeFooter .termsPrivacyCont a {
  font-size: 9px;
  color: #999;
  padding-right: 2%;
  text-decoration: underline;
}
.lightModeFooter .motifContainer {
  position: relative;
  top: 0;
  left: 0;
  width: 120%;
  height: 60px;
}

.darkModeFooter {
  font-family: "Kia Regular", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #05141F;
  width: 100%;
  padding: 2% 20px 12% 20px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .darkModeFooter {
    padding: 2% 30px 12% 30px;
  }
}
.darkModeFooter div {
  padding: 1% 0 1% 0;
}
.darkModeFooter .listContainer {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: #999;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  z-index: 2;
}
@media (max-width: 414px) {
  .darkModeFooter .listContainer {
    font-size: 12px;
  }
}
.darkModeFooter .listContainer p,
.darkModeFooter .listContainer a {
  padding: 5px 0 5px 0;
}
.darkModeFooter .listContainer .footerHomeLink {
  background-color: transparent;
  border: none;
}
.darkModeFooter .listContainer .footerModelName {
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
}
@media (max-width: 414px) {
  .darkModeFooter .listContainer .footerModelName {
    font-size: 13px;
  }
}
.darkModeFooter .socialContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.darkModeFooter .socialContainer a {
  padding: 7px;
}
.darkModeFooter .discFooterContainer {
  width: 100%;
  height: 30px;
}
.darkModeFooter .discFooterContainer .discFooter {
  font-size: 9px;
  color: #fff;
  padding: 0;
}
.darkModeFooter .termsPrivacyCont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.darkModeFooter .termsPrivacyCont a {
  font-size: 9px;
  color: white;
  padding-right: 2%;
  text-decoration: underline;
}
.darkModeFooter .motifContainer {
  position: relative;
  top: 0;
  left: 0;
  width: 120%;
  height: 60px;
}

.qrContainer {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .qrContainer {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: #05141F;
    overflow: hidden;
  }
  .qrContainer .ctaQRContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
    color: white;
  }
  .qrContainer .ctaQRContainer img:first-child {
    max-width: 300px;
    margin-bottom: 40px;
  }
  .qrContainer .ctaQRContainer img:last-child {
    margin-top: 40px;
    max-width: 150px;
  }
  .qrContainer .termsContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 20px;
    padding: 10px;
    position: relative;
    bottom: 3%;
  }
  .qrContainer .termsContainer a {
    text-align: center;
    color: #fff;
    font-size: 9px;
    padding-right: 2%;
    text-decoration: underline;
  }
}
.darkModeNav {
  font-family: "Kia Regular", sans-serif;
  background-color: #05141F;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 20px 20px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .darkModeNav {
    padding: 20px 20px 20px 30px;
  }
}
.darkModeNav .navBtn {
  border: none;
  background-color: transparent;
}
.darkModeNav .nameContainer {
  border-left: solid 1px #fff;
  margin-left: 10px;
}
.darkModeNav .nameContainer p {
  text-transform: capitalize;
  margin-left: 10px;
  color: #fff;
}

.lightModeNav {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 20px 20px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .lightModeNav {
    padding: 20px 20px 20px 30px;
  }
}
.lightModeNav .navBtn {
  border: none;
  background-color: transparent;
}
.lightModeNav .nameContainer {
  border-left: solid 1px #000;
  margin-left: 10px;
}
.lightModeNav .nameContainer p {
  text-transform: capitalize;
  margin-left: 10px;
  color: #000;
}

.footerModelName {
  font-family: "Kia Regular", sans-serif;
  border: none;
  margin-left: 10px;
  font-size: 18px;
  color: #05141F;
  background-color: transparent;
  text-transform: capitalize;
}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 22;
  left: 0;
}

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: #05141F;
  overflow: hidden;
}
.loaderContainer .graphicContainer {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  margin: 0 auto;
}
@media (orientation: landscape) {
  .loaderContainer .graphicContainer {
    width: 20%;
  }
}
.loaderContainer .graphicContainer .barContainer {
  position: relative;
  margin-top: 10px;
  width: 100%;
  height: 3px;
  top: 0;
  left: 0;
}
.loaderContainer .graphicContainer .barContainer .bar {
  height: 100%;
  width: 1px;
  background-color: #fff;
}

.introContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: black;
  overflow: hidden;
}
.introContainer .video-container {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.introContainer .video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.accordion-wrapper {
  width: 100%;
  background-color: #fff;
}

.accordion-wrapper:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 92%;
  padding-top: 0px;
  border-bottom: 1px solid #707070;
}

.accordion-item {
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
  display: block;
}

.accordion-item.collapsed {
  display: none;
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  width: 100%;
  font-size: 18px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  color: #707070;
  height: 70px;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.accordion-title .offerBtnName {
  font-family: "Kia Regular", sans-serif;
  margin-left: 4%;
}
.accordion-title img {
  width: 120px;
  height: 60px;
  margin: 0 5% 0 2%;
}
.accordion-title img + span {
  text-transform: capitalize;
  font-family: "Kia Regular", sans-serif;
}
.accordion-title .arrowContainer.open {
  width: 12px;
  height: 12px;
  transform: rotate(180deg);
}
.accordion-title .arrowContainer {
  background: url("../../../public/images/global/arrowaccordian.svg") no-repeat;
  width: 12px;
  height: 12px;
  right: 4%;
  position: absolute;
}

.accordion-one {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.accordion-one .blankContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #fff;
  border: none;
}
.accordion-one .blankContainer span {
  font-family: "Kia Regular", sans-serif;
  font-size: 18px;
  color: #707070;
}
.accordion-one .blankContainer img {
  width: 120px;
  height: 60px;
  margin: 0 5% 0 2%;
}
.accordion-one .blankContainer .offerBtnName {
  margin-left: 4%;
}

.accordion-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.accordion-content .btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #E6E6E6;
  border: none;
}
.accordion-content .btnContainer span {
  font-family: "Kia Regular", sans-serif;
  font-size: 18px;
  cursor: pointer;
  color: #707070;
}
.accordion-content .btnContainer img {
  width: 120px;
  height: 60px;
  margin: 0 5% 0 2%;
}
.accordion-content .btnContainer .offerBtnName {
  margin-left: 4%;
}
.accordion-content .selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: #CCC;
  border: none;
}
.accordion-content .selected span {
  font-family: "Kia Regular", sans-serif;
  font-size: 18px;
  cursor: pointer;
  color: #707070;
}
.accordion-content .selected img {
  width: 120px;
  height: 60px;
  margin: 0 5% 0 2%;
}
.accordion-content .selected .offerBtnName {
  margin-left: 4%;
}

.financeSection {
  font-family: "Kia Regular", sans-serif;
  background-color: #F4F4F4;
  padding: 20px;
  font-size: 12px;
}
@media (min-width: 768px) {
  .financeSection {
    padding: 20px 30px 20px 30px;
  }
}
.financeSection .zipId {
  color: #999999;
}
.financeSection .tallyOffer {
  padding: 0;
}
.financeSection .offerModule {
  background-color: #fff;
  padding: 15px;
  margin: 15px 0 15px 0;
}
@media (min-width: 768px) {
  .financeSection .offerModule {
    text-align: center;
  }
}
.financeSection .offerModule .offerDate {
  font-size: 12px;
  color: #525252;
}
.financeSection .offerModule .offerDesc {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 100;
}
.financeSection .offerModule .ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.financeSection .offerModule .ctaContainer button {
  border: solid 1px #000;
  margin: 15px 10px 15px 10px;
  font-size: 13px;
  width: 141px;
  height: 40px;
}
@media (max-width: 320px) {
  .financeSection .offerModule .ctaContainer button {
    width: 121px;
    height: 30px;
    font-size: 9px;
  }
}
.financeSection .offerModule .ctaContainer .quoteCta {
  background-color: #05141F;
  color: #fff;
}
.financeSection .offerModule .ctaContainer .inventoryCta {
  background-color: #fff;
  color: #000;
}
.financeSection .discContainer {
  font-size: 12px;
  color: #525252;
  padding: 5px 10px 5px 10px;
  background-color: #fff;
}
.financeSection .discContainer p > b {
  letter-spacing: -0.5px;
}

.leaseSection {
  font-family: "Kia Regular", sans-serif;
  background-color: #F4F4F4;
  padding: 20px;
  font-size: 12px;
}
@media (min-width: 768px) {
  .leaseSection {
    padding: 20px 30px 20px 30px;
  }
}
.leaseSection .zipId {
  color: #999999;
}
.leaseSection .tallyOffer {
  padding: 0;
}
.leaseSection .offerModule {
  background-color: #fff;
  padding: 15px;
  margin: 15px 0 15px 0;
}
@media (min-width: 768px) {
  .leaseSection .offerModule {
    text-align: center;
  }
}
.leaseSection .offerModule .offerDate {
  font-size: 12px;
  color: #525252;
}
.leaseSection .offerModule .offerDesc {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 100;
}
.leaseSection .offerModule .ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.leaseSection .offerModule .ctaContainer button {
  border: solid 1px #000;
  margin: 15px 10px 15px 10px;
  font-size: 13px;
  width: 141px;
  height: 40px;
}
@media (max-width: 320px) {
  .leaseSection .offerModule .ctaContainer button {
    width: 121px;
    height: 30px;
    font-size: 9px;
  }
}
.leaseSection .offerModule .ctaContainer .quoteCta {
  background-color: #05141F;
  color: #fff;
}
.leaseSection .offerModule .ctaContainer .inventoryCta {
  background-color: #fff;
  color: #000;
}
.leaseSection .discContainer {
  font-size: 12px;
  color: #525252;
  padding: 5px 10px 5px 10px;
  background-color: #fff;
}
.leaseSection .discContainer p > b {
  letter-spacing: -0.5px;
}

.cashSection {
  font-family: "Kia Regular", sans-serif;
  background-color: #F4F4F4;
  padding: 20px;
  font-size: 12px;
}
@media (min-width: 768px) {
  .cashSection {
    padding: 20px 30px 20px 30px;
  }
}
.cashSection .zipId {
  color: #999999;
}
.cashSection .tallyOffer {
  padding: 0;
}
.cashSection .offerModule {
  background-color: #fff;
  padding: 15px;
  margin: 15px 0 15px 0;
}
@media (min-width: 768px) {
  .cashSection .offerModule {
    text-align: center;
  }
}
.cashSection .offerModule .offerDate {
  font-size: 12px;
  color: #525252;
}
.cashSection .offerModule .offerDesc {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 100;
}
.cashSection .offerModule .ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.cashSection .offerModule .ctaContainer button {
  border: solid 1px #000;
  margin: 15px 10px 15px 10px;
  font-size: 13px;
  width: 141px;
  height: 40px;
}
@media (max-width: 320px) {
  .cashSection .offerModule .ctaContainer button {
    width: 121px;
    height: 30px;
    font-size: 9px;
  }
}
.cashSection .offerModule .ctaContainer .quoteCta {
  background-color: #05141F;
  color: #fff;
}
.cashSection .offerModule .ctaContainer .inventoryCta {
  background-color: #fff;
  color: #000;
}
.cashSection .discContainer {
  font-size: 12px;
  color: #525252;
  padding: 5px 10px 5px 10px;
  background-color: #fff;
}
.cashSection .discContainer p > b {
  letter-spacing: -0.5px;
}

.militarySection {
  font-family: "Kia Regular", sans-serif;
  background-color: #F4F4F4;
  padding: 20px;
  font-size: 12px;
}
@media (min-width: 768px) {
  .militarySection {
    padding: 20px 30px 20px 30px;
  }
}
.militarySection .zipId {
  color: #999999;
}
.militarySection .tallyOffer {
  padding: 0;
}
.militarySection .offerModule {
  background-color: #fff;
  padding: 15px;
  margin: 15px 0 15px 0;
}
@media (min-width: 768px) {
  .militarySection .offerModule {
    text-align: center;
  }
}
.militarySection .offerModule .offerDate {
  font-size: 12px;
  color: #525252;
}
.militarySection .offerModule .offerDesc {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 100;
}
.militarySection .offerModule .ctaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.militarySection .offerModule .ctaContainer button {
  border: solid 1px #000;
  margin: 15px 10px 15px 10px;
  font-size: 13px;
  width: 141px;
  height: 40px;
}
@media (max-width: 320px) {
  .militarySection .offerModule .ctaContainer button {
    width: 121px;
    height: 30px;
    font-size: 9px;
  }
}
.militarySection .offerModule .ctaContainer .quoteCta {
  background-color: #05141F;
  color: #fff;
}
.militarySection .offerModule .ctaContainer .inventoryCta {
  background-color: #fff;
  color: #000;
}
.militarySection .discContainer {
  font-size: 12px;
  color: #525252;
  padding: 5px 10px 5px 10px;
  background-color: #fff;
}
.militarySection .discContainer p > b {
  letter-spacing: -0.5px;
}

