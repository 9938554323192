.linksSection {
	font-family: 'Kia Regular', sans-serif;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #05141F;
    width: 100%;
    padding: 20px 20px 0 20px;

    @include media(">=768px"){
    	padding:20px 20px 0px 30px;
    }

    ul {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        padding: 40px 0 0 0;

        li {
            padding: 2% 0 2% 0;

            .releaseCopy {
                opacity: 0.4;
            }
        }
    }

}