/* =========================================
  VARIABLES
======================================== */
$breakpoints: (smPhone:360px, phone: 600px, desktop: 1080px);

// colors
$tibPink: #ec027d;
$grey: #f1f1f1;
$darkgrey: #808285;

/* =========================================
  MIXINS
======================================== */

@mixin centerCenter {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin vertCenter {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin horzCenter {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@mixin button {
  cursor: pointer;
}

@mixin circle($width) {
  -webkit-border-radius: $width/2;
  -moz-border-radius: $width/2;
  border-radius: $width/2;
  width:$width;
  height:$width;
}

@mixin backgroundImage($url) {
  background-image:url('#{$url}');
  background-size: cover;
  background-position: center center;
}

@mixin image-2x($image) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size:contain;
  }
}

@mixin defaultTransition {
  transition: .3s all ease-out;
}

/* =========================================
  FONTS
======================================== */

// @import url("https://use.typekit.net/dez8kij.css");
// @import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@1,500&display=swap');

$defaultFontSize: 22px;

// @import url('http://fonts.cdnfonts.com/css/copperplate');
//@import url('../images/fonts/KiaSignatureBold.woff');


@font-face {
    font-family: 'Kia Bold';
    src: url('../../../public/fonts/KiaSignatureBold.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Kia light';
    src: url('../../../public/fonts/KiaSignatureLight.woff') format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'Kia Regular';
    src: url('../../../public/fonts/KiaSignatureRegular.woff') format('woff');
    font-display: swap;
}

/* =========================================
  RESET
======================================== */

* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Kia Regular', sans-serif;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  background-color: #05141F;
  font-family: 'Kia Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  color:#FFF;
  -webkit-font-smoothing: antialiased;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}


a {
  text-decoration: none;
  color: inherit;
  border: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.hidden {
  display: none;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

img {
  color:#fff
}

/* =========================================
  TRANSITIONS
======================================== */

