.loaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background-color: #05141F;
    overflow: hidden;

    .graphicContainer {
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        margin: 0 auto;

        @media (orientation: landscape) {
            width: 20%;
        }

        .barContainer {
            position: relative;
            margin-top: 10px;
            width: 100%;
            height: 3px;
            top: 0;
            left: 0;

            .bar {
                height: 100%;
                width: 1px;
                background-color: #fff;
            }
        }

    }



}