header {
    font-family: 'Kia Regular', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-color: #05141F;
    font-size: 26px;
    position: relative;
    overflow: hidden;
    padding-bottom: 140%;

    @media (orientation: landscape) {
        padding-bottom: 0;
        background-position: 80% 70%;
    }

    @media (min-height: 767px) and (max-width: 375px) {
        background-position: 65% 70%;
    }


    h3,
    p {
        color: #fff;
    }

    .tagContainer {
        position: absolute;
        // top: -20%;
        width: 170%;
        height: auto;
        left: 20px;
        overflow: hidden;


        @media (orientation: landscape) {
            bottom: 4%;
            width: 120%;
        }

        @media (min-height: 767px) and (max-width: 375px) {
            top: -10%;
        }

        @media only screen and (min-width: 600px) and (max-width: 900px) and (orientation: portrait) {
            /* Styles for tablet in portrait mode */
            top: -45%;
        }

        .motif {
            overflow: hidden;
        }
    }

    .copyContainer {
        position: absolute;
        font-family: "Kia Regular", sans-serif;
        font-size: 26px;
        width: 70%;
        height: 20%;
        left: 1.8em;
        top: 8%;
        line-height: 1.2em;
        user-select: none;

        @include media("<=375px"){ 
            font-size: 20px;
        }

        @include media(">=768px"){
            left: 7%;
            font-size: 29px;
        }

        @include media(">=845px"){
            left: 7%;
            font-size: 29px;
        }

        @include media(">=1000px"){
            left: 7%;
            font-size: 39px;
        }


    }

    .awardPresent {
        flex-direction: row;
        justify-content: space-between;

        @media (orientation: landscape) {
            flex-direction: row-reverse;
            justify-content: flex-start;
        }
    }

    .awardNone {
        flex-direction: column;
        justify-content: center;
    }

    .btmHeaderContainer {
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        bottom:3rem;
        right: 0px;

        @media (min-width: 768px) and (max-width: 820px) {
        bottom:9%; 
        }

        .awardHdrContainer {
            padding: 20px;
            height: auto;
            width: 50%;
            display: flex;

            @media (orientation: landscape) {
                width: 35%;
            }

            @include media(">=768px"){
                width: 29.6%;
            }

            img {
                width: 100%
            }
        }

        .arrowContainer {
            font-size: 14px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            // justify-content: center;

            @media (max-height: 414px) {
                position: absolute;
                flex-direction: row;
                bottom: 7%;
                right: 0;
                padding: 1%;
            }

            .lmAwardNone {
                display: block;
                cursor: pointer;

                @media (orientation: landscape) {
                    padding-top: 10%;
                }
            }

            .lmAwardPresent {
                cursor: pointer;
                display: none;
            }

            button {
                background-color: transparent;
                background: url('../../../public/images/global/arrowdown.svg') no-repeat 50% 10px;
                width: 22px;
                height: 22px;
                border: none;
                cursor: pointer;
                margin-bottom: 10px;
                font-size: 0;
                padding: 20px 20px 20px 60px;

                @media (max-height: 414px) {
                    padding: 20px 30px 30px 30px;
                }
            }
        }

    }
}

.ev6Header {
    background-image: url('../../../public/images/ev6/homehero.jpg');
    background-position: 80% 70%;
}

.forteHeader {
    background-image: url('../../../public/images/forte/homehero.jpg');
    background-position: 80% 70%;
}

.sportageHeader {
    background-image: url('../../../public/images/sportage/homehero.jpg');
    background-position: 80% 70%;
}

.niroHeader {
    background-position: 34% 20%;
    background-image: url('../../../public/images/niro/homehero.jpg');


    @media (orientation: landscape) {
        padding-bottom: 0;
        background-position: 5% 98%;
        background-image: url('../../../public/images/niro/homehero_landscape.jpg') !important;
    }
}

.tellurideHeader{
    background-image: url('../../../public/images/telluride/homehero.jpg');
    background-position: 80% 70%;
    @media (max-width: 414px) {

    }
}


.ev9Header {
    background-image: url('../../../public/images/ev9/homehero.jpg');
    background-position: 65% 20%;


    @media (orientation: landscape) {
        background-position: 5% 72%;
        background-size: 120%;

    }
         

}

.k4Header {
    background-image: url('../../../public/images/k4/homehero.jpg');
    background-position: 65% 20%;


    @media (orientation: landscape) {
        background-position: 55% 82%;
        background-size: 120%;

    }
         

}