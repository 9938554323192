.modal-container {
    font-family: 'Kia Regular', sans-serif;
    background-color: #05141F;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    overflow: hidden;

    .modal {
        background-color: #05141F;
        position: fixed;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;

        .customBtnVideo {
            font-family: 'Kia Regular', sans-serif;
            position: absolute;
            top: 2%;
            right: 2%;
            margin-left: auto;
            background: url('../../../public/images/global/closemodal.svg') no-repeat rgba(0, 0, 0, 0.4);
            background-position: 20px 20px;
            background-color: #05131F;
            width: 60px;
            height: 60px;
            border: none;
            cursor: pointer;
            font-size: 0;
            z-index: 999;

            @media (max-height: 375px) {
                top: 22%;
            }
        }

        .customBtnImage {
            font-family: 'Kia Regular', sans-serif;
            position: absolute;
            top: 2%;
            right: 2%;
            margin-left: auto;
            background: url('../../../public/images/global/closemodal.svg') no-repeat #05141f;
            background-position: 20px 20px;
            width: 60px;
            height: 60px;
            border: none;
            cursor: pointer;
            font-size: 0;
            z-index: 999;
        }


        p {
            color: #000;
            font-family: sans-serif;
            text-transform: unset;
        }

        .inputFake {
            opacity: 0;
            background-color: transparent;
            border: none;
            width: 0;
            height: 0;
            left: -100px;
            top: -100px;
            position: absolute;
        }

        .main-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .mp4-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                height: 100%;

                video {
                    width: 100%;

                    @media (max-height: 414px){
                        width: 80%;
                    }
                }

            }


            .video-container {
                display: flex;
                align-items: center;
                justify-content: center;
                top: 50vh;
                transform: translateY(-50%);
                position: relative;
                padding-bottom: 51.25%;
                /* 16:9 */
                width: 100%;
                vertical-align: baseline;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    img {
                        background-size: contain!important;
                    }

                }
            }

            .image-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100vw;
                height: 100vh;

                .pinchZoomContainer {
                    width: 125px;
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    color: #fff;
                    top: 15px;
                    padding: 10px;
                    left: 10px;
                    position: absolute;
                    z-index: 2;

                    .pinchCopy {
                        position: absolute;
                        color: #fff;
                        font-family: 'Kia Regular', sans-serif;
                        // -webkit-animation: 3s ease 0s normal forwards 1 fadeOut;
                        // animation: 3s ease 0s normal forwards 1 fadeOut;
                        @media (orientation: landscape) {
                            background: rgba(0, 0, 0, .7);
                            padding: 2px;
                        }
                    }
                }

                @keyframes fadeOut {
                    0% {
                        opacity: 1;
                    }

                    66% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                @-webkit-keyframes fadeOut {
                    0% {
                        opacity: 1;
                    }

                    66% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                    }
                }

                .pinchImage {
                    width: 100%;

                    @media (max-height: 414px){
                        width: 60vw;

                    }

                }

                .kvfysmfp {
                    touch-action: none;
                    overflow: visible;
                }

            }


            .discModalContainer {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                text-align: left;
                padding: 20% 10% 20% 10%;
                width: 100%;
                height: 100%;
                overflow-y: scroll;

                @media (orientation: landscape) {
                    margin-top: 0;
                }
                p {
                    font-family: "Kia Regular", sans-serif;
                    text-align: left;
                    font-size: 12px !important;
                    color: #fff;
                    margin-bottom: 30px; 
                    background-color: #05131F;
                    border: none;
                    resize: unset;
                    width: 100%;
                    height: auto; 
                    // overflow: hidden;
                    // padding-bottom: inherit;

                    @media (orientation: landscape) {
                        font-size: 12px!important;
                    }

                    sup {
                        font-size: 8px;
                        padding-right: 4px;
                    }

                    a {
                        text-decoration: underline;
                    }
                }

                div {
                    position: relative;
                    top: 0;
                    width: 100%;
                    height: auto;
                    padding-bottom: 5%;
                    display: block;
                }
            }

            .title {
                padding-bottom: 10px;

                p,
                input {
                    font-family: 'Kia Regular', sans-serif;
                    text-transform: uppercase;
                    font-size: 24px;
                    line-height: 28px;
                    font-weight: bold;
                    padding: 0;
                    text-align: center;
                    border: 0;

                    @include media("<=500px") {
                        font-size: 24px;
                        line-height: 26px;
                    }

                    @include media("<=375px") {
                        font-size: 20px;
                        line-height: 22px;
                    }
                }
            }

            .terms-copy {
                p {
                    text-align: left;
                    line-height: 20px;
                    font-size: 16px;

                    @include media("<=500px") {
                        font-size: 16px;
                    }

                    @include media("<=375px") {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}