.zipCodeFormSection {
    font-family: 'Kia Regular', sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 70vh;
    padding: 0 10% 10% 10%;
    background-color: #fff;

    p {
        color: #05141f;
        font-size: 26px;
        margin-bottom: 20px;
    }

    textarea {
        color: #05141f;
        font-size: 26px;
        margin-bottom: 20px;
        width: 90%;
        font-family: 'Kia Regular', sans-serif;
        border: none;
        resize: none;
    }

    .mc__field-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;

        .errorMsg {
            margin-top: 10px;
            color: #FF0000;
            font-size: 12px;
            border: none;
            user-select: none;
            visibility: hidden;
            
            input:focus {
                margin-top: 10px;
                color: #FF0000;
                font-size: 12px;
                border: none;
                user-select: none;
            }
        }

        .show {
            visibility: visible;
        }

        .mc__form {
            font-family: 'Kia Regular', sans-serif;
            display: flex;
            flex-direction: row;
            border-bottom: solid 1px #999;
            width: 100%;

            .inputField__label {
                width: 100%;
            }

            .submitDim {
                opacity: 0.5;
            }

            .submit {
                opacity: 1;
            }

            input {
                font-family: 'Kia Regular', sans-serif;
                font-size: 18px;
                border: none;
                width: 100%;
                color: #05141f !important;
                padding: 10px;
            }

            input:nth-child(2) {
                margin-left: auto;
                background-color: #fff;
                background: url('../../../public/images/global/arrowsubmit.svg') no-repeat 20px 7px;
                color: #fff;
                width: 50px;
                height: 30px;
                border: none;
                padding: 20px;
                cursor: pointer;
                font-size: 0;
            }

        }

    }

}